import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`r.i.c.e. is a non-profit research organization focused on health and well-being in India. Our core focus is on children in rural north India. Our research studies health care at the start of life, sanitation, air pollution, maternal health, social inequality, and other dimensions of population-level social wellbeing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      