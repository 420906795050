/** @jsx jsx */
import { jsx, useColorMode, Styled } from 'theme-ui'
import { Link } from 'gatsby'
import { Flex, Button } from '@theme-ui/components'
import useSiteMetadata from '../hooks/use-site-metadata'
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config'
import ColorModeToggle from './colormode-toggle'
import Navigation from './navigation'
import replaceSlashes from '../utils/replaceSlashes'

const Header = () => {
  const { siteTitle } = useSiteMetadata()
  const { navigation: nav, externalLinks, basePath } = useMinimalBlogConfig()
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <header sx={{ mb: [3, 4] }}>
      <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>
        <Link
          to={replaceSlashes(`/${basePath}`)}
          aria-label={`${siteTitle} - Back to home`}
          sx={{ color: `heading`, textDecoration: `none` }}>
          <h1 sx={{ my: 0, fontWeight: `medium`, fontSize: [3, 4] }}>
            {siteTitle}
          </h1>
        </Link>
        <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
      </Flex>
      <div sx={{ mt: [2, 3] }}>
        <a
          href='https://www.paypal.com/donate/?hosted_button_id=Q9FEKD3MM4FK6'
          target='_blank'>
          <Button variant='block'>Donate to r.i.c.e.</Button>
        </a>
      </div>
      <div
        sx={{
          boxSizing: `border-box`,
          display: `flex`,
          variant: `dividers.bottom`,
          alignItems: `center`,
          justifyContent: `space-between`,
          mt: 3,
          color: `secondary`,
          a: { color: `secondary`, ':hover': { color: `heading` } },
          flexFlow: `wrap`
        }}>
        <Navigation nav={nav} />
        {externalLinks && externalLinks.length > 0 && (
          <div
            sx={{ 'a:not(:first-of-type)': { ml: 3 }, fontSize: [1, `18px`] }}>
            {externalLinks.map((link) => (
              <Styled.a key={link.url} href={link.url}>
                {link.name}
              </Styled.a>
            ))}
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
