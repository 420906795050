import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/sanitation"
        }}>{`Sanitation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/maternal"
        }}>{`Maternal Health`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/social-hierarchy"
        }}>{`Social Hierarchy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/environment"
        }}>{`Environment`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      