/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Grid, Box } from '@theme-ui/components'

// @ts-ignore
import FooterRight from '../texts/footer-right.mdx'
// @ts-ignore
import FooterLeft from '../texts/footer-left.mdx'
// @ts-ignore
import FooterCenter from '../texts/footer-center.mdx'
// @ts-ignore
import FooterLeftNav from '../texts/footer-left-nav.mdx'

const Footer = () => {
  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `row`, `row`],
        variant: `dividers.top`,
      }}
    >
      <Grid gap={4} columns={[1, 3, 3]} sx={{}}>
        <section
          aria-label='Left Footer Column'
          sx={{
            padding: '2%',
            // borderRight: '1px solid',
            borderColor: 'tertiary',
            mb: [1, 1, 2],
          }}
        >
          <section
            sx={{
              mb: [2, 3, 4],
            }}
          >
            <h2>About</h2>
          </section>
          <section
            sx={{
              mb: [2, 3, 4],
            }}
          >
            <Box
              sx={{
                img: {
                  width: '40%'
                }
              }}
            >
              <img src="./images/rice-logo.png"></img>
            </Box>
            <FooterLeft />
            

          </section>
          <section
            sx={{
              mb: [2, 3, 4],
            }}
          >
            <form action="https://www.paypal.com/donate" method="post" target="_blank">
              {/* <notes>old donate value="8VXWCHPEMA6GC" /notes> */}
              <input type="hidden" name="hosted_button_id" value="Q9FEKD3MM4FK6" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="Donate with PayPal" alt="Donate with PayPal" />
              <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            {/* <div id="donate-button-container">
              <div id="donate-button">
              </div>
              <script src="https://www.paypalobjects.com/donate/sdk/donate-sdk.js" charset="UTF-8">
              </script>
              <script>
                PayPal.Donation.Button({{
                  env: 'production',
                  hosted_button_id: 'Q9FEKD3MM4FK6',
                  image: {
                    src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif',
                    alt: 'Donate with PayPal button',
                    title: 'PayPal - The safer, easier way to pay online!',
                  }
                }}).render('#donate-button');
              </script>
            </div> */}

          </section>

          <section
            sx={{
              mb: [2, 3, 2],
              ul: { margin: 0, padding: 0 },
              li: { listStyle: `none`, mb: 1 },
              a: { variant: `links.footerList` },
            }}
          >
            <FooterLeftNav />
          </section>
        </section>

        <section
          aria-label='Middle Footer Column'
          sx={{
            padding: '3%',
            // borderRight: '1px solid',
            borderColor: 'tertiary',
          }}
        >
          <h2>Research Themes</h2>
          <section
            sx={{
              ul: { margin: 0, padding: 0 },
              li: { listStyle: `none`, mb: 1 },
              a: { variant: `links.footerList` },
              mb: [3, 4, 5],
            }}
          >
            <FooterCenter />
          </section>
        </section>

        <section
          aria-label='Right Footer Column'
          sx={{
            padding: '1%',
            // borderLeft: '1px solid #333',
            mb: [3, 4, 5],
          }}
        >
          <h2>Content by Category</h2>
          <section
            sx={{
              ul: { margin: 0, padding: 0 },
              li: { listStyle: `none`, mb: 1 },
              a: { variant: `links.footerList` },
            }}
          >
            <FooterRight />
          </section>
        </section>
      </Grid>
    </footer>
  )
}

export default Footer
