import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/research"
        }}>{`Research`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/data"
        }}>{`Data`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/policy-outreach"
        }}>{`Policy Briefs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/blog-post"
        }}>{`Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/media"
        }}>{`Media`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags/people"
        }}>{`People`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tags"
        }}>{`See All Tags`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      